<template>
  <component :is="curLayoutComponent" :hideTab="route.meta.hideTab" :theme="route.meta.theme">
    <router-view v-slot="{ Component, route:comRoute }">
      <keep-alive>
        <component v-if="comRoute.meta.keepAlive" :is="Component" :key="comRoute.name" />
      </keep-alive>
      <component v-if="!comRoute.meta.keepAlive" :is="Component" :key="comRoute.name" />
    </router-view>
  </component>

  <AdModal :ads="adStore.adDialog"></AdModal>
  <NoticeModal />
</template>

<script>
import useGlobalStore from '@/store/global'
import useAdStore from '@/store/ad'

export default {
  async asyncData({ store }){
    // const globalStore = useGlobalStore(store)
    // await Promise.allSettled([
    //   globalStore.getSetting(),
    //   globalStore.getNotice(),
    //   globalStore.getCategoryTree(),
    // ]).then(() => {
    //   // 首次渲染标记
    //   globalStore.firstRendered = true
    // })
  }
}
</script>

<script setup>
import { watch, shallowRef, defineAsyncComponent, onMounted } from 'vue'
import AdModal from '@/components/ad/Modal.vue'
import NoticeModal from '@/components/noticeModal.vue'
import { useRoute } from 'vue-router'
import useDialLogger from '@/utils/dialLogger'
import { getBrowerInfo } from '@/utils/device'

const route = useRoute()
const adStore = useAdStore()


// 布局异步组件
const layoutComponents = {
  'default': defineAsyncComponent(() => import('@/layout/Default.vue')),
  'blank': defineAsyncComponent(() => import('@/layout/Blank.vue')),
  'navbar': defineAsyncComponent(() => import('@/layout/Navbar.vue')),
  'goback': defineAsyncComponent(() => import('@/layout/Goback.vue')),
  'wechatJump': defineAsyncComponent(() => import('@/layout/WechatJump.vue')),
}
const curLayoutComponent = shallowRef(layoutComponents[route.meta.layout || 'default'])

// 布局组件监听
watch(() => route.path, () => {
  if (!import.meta.env.SSR && getBrowerInfo().client.isWechat) {
    curLayoutComponent.value = layoutComponents['wechatJump']
  } else {
    curLayoutComponent.value = layoutComponents[route.meta.layout || 'default']
  }
})

onMounted(() => {
  // 域名拨测
  // useDialLogger()
})
</script>

<style lang="scss">
.van-notice-bar__content{
  height: 100%;
  p{
    margin: 0;
  }
}
</style>
import CryptoJS from 'crypto-js'

/**加密解密key*/
export const EN_DC_KEY = 'NHboMHZerxFQ401E'
/**加密解密iv*/
export const EN_DC_IV = 'i7JeCEIMVrj2W9xN'

// 加密方法
export function encrypt(data, k = EN_DC_KEY, i = EN_DC_IV) {
  try {
    const key = CryptoJS.enc.Utf8.parse(k) // 十六位十六进制数作为密钥
    const iv = CryptoJS.enc.Utf8.parse(i) // 十六位十六进制数作为密钥偏移量
    var srcs = CryptoJS.enc.Utf8.parse(data)
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
  } catch (error) {
    console.log(error)
  }
}

// 解密方法
export function decrypt(data, k = EN_DC_KEY, i = EN_DC_IV) {
  try {
    const key = CryptoJS.enc.Utf8.parse(k) // 十六位十六进制数作为密钥
    const iv = CryptoJS.enc.Utf8.parse(i) // 十六位十六进制数作为密钥偏移量
    var decrypt = CryptoJS.AES.decrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    return decryptedStr.toString()
  } catch (error) {
    console.log(error)
  }
}

//解密base64
// 这个字符串应从PHP脚本获取
export function decryptImgUrl(encryptedBase64) {
  let encryptedText = window.atob(encryptedBase64)
  let shift = 3 // 与PHP脚本中的移位量相同
  let decryptedText = ''
  // 辅助函数：模拟PHP的ord函数
  function ord(string) {
    return string.charCodeAt(0)
  }
  for (let i = 0; i < encryptedText.length; i++) {
    let char = encryptedText[i]
    let decryptedChar = String.fromCharCode((ord(char) - shift + 256) % 256)
    decryptedText += decryptedChar
  }
  let base64Image = window.atob(decryptedText)
  // 创建Blob对象和URL
  let byteNumbers = new Array(base64Image.length)
  for (let i = 0; i < base64Image.length; i++) {
    byteNumbers[i] = base64Image.charCodeAt(i)
  }
  let byteArray = new Uint8Array(byteNumbers)
  let blob = new Blob([byteArray], { type: 'image/jpeg' })
  let imageUrl = URL.createObjectURL(blob)
  return imageUrl
}


export const base64Encode = str => CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(str))
export const base64Decode = encryptedStr => CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedStr))
import {
  createRouter,
  createWebHistory,
  createMemoryHistory,
} from "vue-router";
import { useRoutes } from "./routes";
import statTrack from "@/utils/statTrack";
import { getBrowerInfo } from "@/utils/device";
import _ from "lodash";

// 需要将原本被传递给 app 的 pinia 实例传递进来，才能取到全局 state
export default ({ store, ssr }) => {
  const router = createRouter({
    // ssr history 必须为 memory 模式
    history: ssr ? createMemoryHistory() : createWebHistory(),
    routes: useRoutes({ store }),
  });

  router.beforeEach((from, to, next) => {
    if (from.path.endsWith("/index.html")) {
      return next(from.path.replace(/(.*)\/index\.html$/, "$1"));
    }
    // 客户端动态设置页面title，ssr服务端通过render函数返回的route.meta动态注入title
    if (from.meta.title && !ssr) {
      document.title = from.meta.title;
    }
    next();
  });

  router.afterEach((to, from) => {
    if (!import.meta.env.SSR) {
      // 默认全部页面进入滚动到顶部
      window.scrollTo(0, 0);

      // 缓存邀请码，且仅首次缓存的邀请码有效
      const inviteCode = localStorage.getItem("inviteCode");
      if (to.query.pucode && !inviteCode) {
        localStorage.setItem("inviteCode", to.query.pucode);
      }

      // from.name不为空，表示单页面跳转过来的，进行手动统计
      if (from.name) {
        statTrack({ fullPath: from.fullPath });
      }
      // const globalStore = useGlobalStore();
      // const agent = globalStore.identityCode; // 你可以从 Vuex store 或其他地方获取这个值
      // console.log("path", to.path);
      // const query = { ...to.query, agent };
      // to.path =
      //   to.path +
      //   (Object.keys(query).length > 0
      //     ? "?" + new URLSearchParams(query).toString()
      //     : "");
      // console.log("to", to.path);
      store.state.value.global.routeJumpCount++;
    }
  });

  return router;
};

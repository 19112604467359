import Tasker from './Tasker'

const tasker = new Tasker({
  maxTasks: 1, // 必须为1，否则拨测存在不准确性（通过jsonp中的window.__mogo_net_heart=1来判断是否已拨通）
  maxRetry: 0,
})

export default url => {
  url = url.replace(/\/$/, '')
  return tasker.add(() => {
    return new Promise((resolve, reject) => {
      const js = document.createElement('script')
      window.__mogo_net_heart = null
      js.onload = () => {
        setTimeout(() => {
          js.remove()
          if (window.__mogo_net_heart) {
            window.__mogo_net_heart = null
            resolve()
          }
          else reject()
        }, 0)
      }
      js.onerror = (e) => {
        js.remove()
        reject(e)
      }
      js.src = `${url}/_web_heart.js?t=${Date.now()}`
      document.body.append(js)
    })
  })
}
export function setupDevToolsPrevention() {
  // 无限的 debugger 兼容性好
  setInterval(function () {
    debuggerCheck();
  }, 100);
  var debuggerCheck = function () {
    function doCheck(a) {
      if (("" + a / a)["length"] !== 1 || a % 20 === 0) {
        (function () {})["constructor"]("debugger")();
      } else {
        (function () {})["constructor"]("debugger")();
      }
      doCheck(++a);
    }
    try {
      doCheck(0);
    } catch (err) {}
  };
  debuggerCheck();
  // 监听窗口大小变化
  //   window.addEventListener("resize", checkDevTools);

  // 阻止F12键和右键菜单
  document.addEventListener("contextmenu", function (e) {
    e.preventDefault(); // 阻止右键菜单
  });

  document.addEventListener("keydown", function (e) {
    if (e.key === "F12" || (e.ctrlKey && e.shiftKey && e.key === "I")) {
      e.preventDefault(); // 阻止F12和Ctrl+Shift+I
    }
  });
}

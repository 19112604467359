import lazy from '@/utils/lazy'
import { decodePicture } from '@/utils/picDecode'

/**
 * 背景图图片解密
 */
export default app => {
  /**
   * 懒加载（仅限图片）
   * @param {Object} binding.value 图片地址
   * @param {Boolean} binding.modifiers 修饰符 unplace 不使用占位图
   */
  
  app.directive('depicbg', {
    mounted(el, binding){
      let url = binding.value
      const { unplace } = binding.modifiers
      if (!url) return
      let textareaEl
      if (el.tagName !== 'TEXTAREA') {
        textareaEl = el.querySelector('textarea')
      }
      if (unplace) {
        el.classList.add('depic-loading')
      } else {
        (textareaEl||el).style.backgroundImage = 'url(/assets/img/loading.png)'
      }

      lazy(el, async () => {
        await decodePicture({ url }).then(bloburl => {
          if (bloburl) {
            (textareaEl||el).style.backgroundImage = `url(${bloburl})`
          } else {
            (textareaEl||el).style.backgroundImage = `url(${url})`
          }
        }).catch(e=>{
          console.error(e)
          // (imgEl||el).src = url
        })
        el.classList.remove('depic-loading')
      })
    }
  })
}
import useGlobalStore from "@/store/global"
import useAdStore from "@/store/ad"
import lazy from '@/utils/lazy'
import Collector from '@/utils/Collector'

export default app => {
  // 广告日志收集器，达到收集器阈值（默认50条记录）自动执行回调函数
  const collector = new Collector({}, logs => {
    const adStore = useAdStore()
    // 返回 Promise 可以回收执行失败的日志，重新执行
    return adStore.sendLogs(logs)
  })

  /**
   * 广告日志上报指令
   * @param {Object} binding.value.id 广告id
   * @param {Object} binding.value.positionId 广告位positionId
   * @param {Boolean} binding.modifiers click广告点击上报 show广告展现上报 不加修饰符表示既展现上报也点击上报
   */
  app.directive('adlog', {
    mounted(el, binding){
      const globalStore = useGlobalStore()
      const group = globalStore.setting.domainGroup
      // 进入视窗监听
      lazy(el, () => {
        const {id, positionId} = binding.value
        const {click, show} = binding.modifiers

        // 广告曝光上报
        const showHandle = () => {
          if (!positionId) return
          // group: 流量端 1发布 2留存 3推广 4安全组
          const logData = {positionId, type:1, group}
          collector.add(logData)
        }

        // 广告点击上报，需要具体的ID
        const clickHandle = () => {
          el.addEventListener('click', () => {
            const logData = {id, type:2, group}
            if (positionId) logData.positionId = positionId
            collector.add(logData)
          })
        }
        
        // 没有修饰指令，进行曝光和点击日志上报
        if (!show && !click) {
          showHandle()
          clickHandle()
        }
        // 仅曝光日志上报
        else if (show) {
          showHandle()
        }
        // 仅点击日报上报
        else if (click) {
          clickHandle()
        }
      })
    }
  })
}
export default function(options){
  let {fullPath, gtagID='G-DQ0TGX05WL'} = options || {}
  if (!fullPath) fullPath = location.href
  if (window._paq) {
    _paq.push(['setCustomUrl', fullPath]);
    _paq.push(['trackPageView']);
  }
  if (window._hmt) {
    _hmt.push(['_trackPageview', fullPath])
  }
  if (gtagID && window.gtag) {
    gtag('config', gtagID, {
      'page_path': fullPath
    })
  }
}
import statTrack from '@/utils/statTrack'

export default app => {
  /**
   * 统计上报（目前支持百度、matomo、谷歌）
   * @param {Object} binding.value.fullPath 目标url，默认本页面url
   * @param {Object} binding.value.loading 防抖参数，表示目标加载中或操作中，需等待加载完或操作完才能进行统计上报
   * @param {Object} binding.value.gtagID 谷歌统计ID
   */
  
  app.directive('stat', {
    mounted(el, binding){
      let { fullPath } = binding.value || {}

      el.addEventListener('click', () => {
        if (binding.value.loading) return
        if (!fullPath) fullPath = window.location
        statTrack({fullPath})
      })
    }
  })
}
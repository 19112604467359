import lazy from '@/utils/lazy'
import { decodePicture } from '@/utils/picDecode'

/**
 * 图片解密
 */
export default app => {
  /**
   * 懒加载（仅限图片）
   * @param {Object} binding.value 图片地址
   * @param {Boolean} binding.modifiers 修饰符 unplace 不使用占位图
   */
  
  app.directive('depic', {
    mounted(el, binding){
      let url = binding.value
      const { unplace } = binding.modifiers
      if (!url) return
      let imgEl
      if (el.tagName !== 'IMG') {
        imgEl = el.querySelector('img')
      }
      if (unplace) {
        el.classList.add('depic-loading')
      } else {
        (imgEl||el).src = '/assets/img/loading.png'
      }

      lazy(el, async () => {
        await decodePicture({ url }).then(bloburl => {
          if (bloburl) {
            (imgEl||el).src = bloburl
          } else {
            (imgEl||el).src = url
          }
        }).catch(e=>{
          console.error(e)
          // (imgEl||el).src = url
        })
        el.classList.remove('depic-loading')
      })
    }
  })
}
import { getToken, clearToken } from "./token";
import { decrypt, encrypt } from "./encrypt";
import { showFailToast } from "vant";
import useGlobalStore from "@/store/global";
import { getOsInfo, getBrowerInfo, networkStat } from "./device";

const isDev = import.meta.env.DEV;
const isSSR = import.meta.env.SSR;

// 开发模式则使用 /api 进行反向代理，其它模式均使用 http
// export const baseUrl = isDev && !isSSR ? '/api' : '/app'
export let baseUrl = import.meta.env.VITE_FETCH_API_BASE_URL || "";

// ssr端重置请求url前缀
if (isSSR) {
  if (process.env.baseFetchUrl) {
    baseUrl = `${process.env.baseFetchUrl}/app`;
  }
}

// request 数据请求类型默认为 application/json
export const DATATYPE = {
  urlencoded: "application/x-www-form-urlencoded",
  formdata: "multipart/form-data",
};

/**
 * 请求函数，数据格式默认为 application/json
 * @param {*} url
 * @param {Boolean} options.needCoded body数据加密
 * @param {JSON} options.body body JSON数据
 * @param {Object} options.headers body JSON数据
 * @param {String} datatype 请求体类型
 * @returns {Promise} 响应数据为 json 或 text 则返回解密数据，html 返回 text，其它情况返回响应体
 */
export const request = (url, options = {}, datatype) => {
  const globalStore = useGlobalStore();
  // 浏览器信息
  const browerInfo = getBrowerInfo().client;
  // 设备信息
  const osInfo = getOsInfo();
  const network = networkStat();
  options.headers = options.headers || {};
  options.headers["platform"] = 1;
  if (options.method === "POST" || options.method === "PUT") {
    options.headers["Content-Type"] = "application/json";
  } else {
    options.headers["Content-Type"] =
      datatype || "application/x-www-form-urlencoded";
  }
  let token = globalStore.userInfo.token;
  if (!isSSR && !token) {
    token = localStorage.getItem("token");
  }
  if (token) {
    options.headers["token"] = token;
  }
  if (browerInfo.name) {
    options.headers["BrowserInfo"] = browerInfo.name;
  }
  if (browerInfo.version) {
    options.headers["BrowserVersion"] = browerInfo.version;
  }
  if (osInfo.name) {
    options.headers["DeviceInfo"] = osInfo.name;
  }
  if (osInfo.version) {
    options.headers["DeviceVersion"] = osInfo.version;
  }
  if (network.type) {
    options.headers["NetInfo"] = network.type;
  }

  url = baseUrl + url;

  return new Promise((resolve, reject) => {
    const fetchHandle = async (res) => {
      if (res.ok) {
        const contentType = res.headers.get("content-type") || "";
        if (
          contentType.indexOf("application/json") > -1 ||
          contentType.indexOf("text/plain") > -1
        ) {
          const resBody = await res.text();
          const decode = JSON.parse(decrypt(resBody));
          if (decode.errCode === 2001) {
            showFailToast("请先登录");
            globalStore.userInfo.token = "";
            if (import.meta.env.SSR) {
              localStorage.removeItem("token");
            }
            globalStore.$patch();
            reject(decode);
          } else if (decode.errCode > 0) {
            // showFailToast(decode.msg || '请求失败')
            reject(decode);
          } else {
            resolve(decode);
          }
        } else if (contentType.indexOf("text/html") > -1) {
          const resText = await res.text();
          resolve(resText);
        } else {
          resolve(res);
        }
      } else {
        reject(res);
      }
    };

    // 需要加密
    if (options.needCoded && options.body) options.body = encrypt(options.body);

    fetch(url, options)
      .then(fetchHandle)
      .catch((e) => {
        console.log(url, "网络请求失败", e);
        if (!isSSR) showFailToast("网络请求失败");
        reject(e);
      });
  });
};

export default request;

const isClient = !import.meta.env.SSR

const getToken = () => {
  if (isClient) {
    return localStorage.getItem('token')
  }
}

const setToken = (token) => {
  if (isClient) {
    localStorage.setItem('token', token)
  }
}

const clearToken = () => {
  if (isClient) {
    localStorage.removeItem('token')
    localStorage.removeItem('uid')
  }
}

export { getToken, setToken, clearToken }

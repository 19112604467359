<template>
  <div>
    <div v-if="show" class="ad-modal">
      <div class="ad-modal-wrapper" v-adlog.show="{positionId: ads[0].positionId}">
        <div class="item" v-for="(ad, i) in ads" :key="ad.id" :class="{actived: i === curPopIdx}">
          <a class="img-wrapper" v-adlog.click="ad" :href="ad.linkUrl" target="_blank" v-depic.unplace="`${globalStore.imgDomain}${ad.image}`">
            <img v-if="globalStore.imgDomain&&ad.image">
            <van-loading type="spinner" :size="24" />
          </a>
          <div class="close" @click="onClose">关闭</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import useGlobalStore from '@/store/global'

const props = defineProps({
  ads: {
    type:Array, default: () => []
  }
})

const globalStore = useGlobalStore()
const curPopIdx = ref(0)

// 弹窗广告不为0，且下标不为null
const show = computed(() => props.ads.length && curPopIdx.value !== null)

const onClose = () => {
  if (props.ads.length-1 > curPopIdx.value) {
    curPopIdx.value++
  } else {
    curPopIdx.value = null
  }
}
</script>

<style lang="scss">
.ad-modal{
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.6);
  z-index: 9999;
  padding-bottom: 6em;
  .ad-modal-wrapper{
    width: 300px;
  }
  .img-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .item{
    position: relative;
    display: none;
    &.actived{
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
    }
    .van-loading{
      display: none;
    }
    .depic-loading{
      text-align: center;
      .van-loading{
        display: inline-block;
      }
      img{
        opacity: 0;
      }
      // &+.close{
      //   display: none;
      // }
    }
    img{
      border-radius: .5em;
    }
    .close{
      position: absolute;
      font-size: 1em;
      // width: 1.8em;
      // height: 1.8em;
      // border: solid 1px rgba(255,255,255,0.9);
      color: #fff;
      top: .9em;
      right: .9em;
      z-index: 100;
      // left: 50%;
      // top: 100%;
      // margin-top: 2em;
      // margin-left: -.9em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: .8em;
      padding: .12em .5em;
      background: rgba(0, 0, 0, .7);
    }
  }
}
</style>
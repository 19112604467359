import "./assets/style/main.scss";
import "./assets/style/mui.scss";
import "vant/es/toast/style";
import { createApp } from "vue";
import App from "./App.vue";
import createRouter from "./router";
import { createPinia } from "pinia";
import useGlobalStore from "@/store/global";
import createDirectives from "./directives";
import { base64Decode, decrypt } from "@/utils/encrypt";
import {
  deepMerge,
  getSubDomain,
  getRootDomain,
  setSubDoamin,
} from "@/utils/common";
import dial from "@/utils/dial";
import { setupDevToolsPrevention } from "./utils/devtools";
import { showLoadingToast, showFailToast } from "vant";

// 导入普通字体图标样式和多色字体图标源
import MIcon from "./components/mui/MIcon.vue";
import MIconJs from "./components/mui/MIconJs.vue";
import "./assets/style/MIcon.css";
import "./assets/style/MIconJs";

!(async () => {
  const app = createApp(App);
  const store = createPinia();

  createDirectives(app);
  app.use(store);
  app.component("MIcon", MIcon);
  app.component("MIconJs", MIconJs);

  // pinia数据的初始化
  if (window.__STATE__) {
    store.state.value = JSON.parse(base64Decode(window.__STATE__));
  }
  // 提取持久化数据
  // let globalPersist = localStorage.getItem('pinia:global')
  // if (globalPersist) {
  //   try{
  //     globalPersist = JSON.parse(globalPersist)
  //     // globalPersist.soft：软持久，不会覆盖ssr数据
  //     // globalPersist.force：强持久，会覆盖ssr数据
  //     store.state.value.global = deepMerge(JSON.parse(decrypt(globalPersist.soft)), store.state.value.global)
  //     store.state.value.global = deepMerge(store.state.value.global, globalPersist.force)
  //   }catch(e){
  //     localStorage.removeItem('pinia:global')
  //     console.warn(e)
  //   }
  // }

  // 此处实例化globaStore，传入store可以继承上面的store数据
  const globalStore = useGlobalStore(store);
  // if (!store.state.value.global.firstRendered) {
  await Promise.allSettled([
    globalStore.getSetting(),
    // globalStore.getNotice(),
    // globalStore.getCategoryTree(),
  ]).then(() => {
    // 首次渲染标记
    globalStore.firstRendered = true;
  });
  // }
  // 本机域名归组
  globalStore.setGroup(globalStore.setting.promotiondomains, 3); //扫描推广组
  globalStore.setGroup(globalStore.setting.landingdomains, 2); //扫描留存组
  // globalStore.setGroup(globalStore.setting.transferdomains, 1) //扫描发布组

  if (import.meta.env.DEV && globalStore.setting.domainGroup == 0) {
    globalStore.setting.domainGroup = 3;
  }
  const router = createRouter({ store, ssr: false });
  app.use(router);
  // 禁用调试 F12
  setupDevToolsPrevention();
  // 挂载vue
  router.isReady().then(() => {
    app.mount("#app");
  });

  //部署统计代码
  const setStatictis = (code) => {
    if (!code) return;
    document.body.append(document.createRange().createContextualFragment(code));
  };
  // 本地域名所属组
  const localGroup = globalStore.setting.domainGroup;
  // 渠道识别码
  const identityCode = getSubDomain(location.origin);

  console.log("group:", localGroup);
  // 测试域名不跳转
  if (location.href == "https://test.mg666.vip/") return;
  // 存在识别码，且域名属于推广组，部署渠道推广统计代码
  if (identityCode && localGroup == 3) {
    const statCode =
      globalStore.setting.statisticsVOMap[identityCode]?.statistics;
    setStatictis(statCode);
  }

  // 存在识别码，且域名属于留存组，部署渠道留存统计代码
  else if (identityCode && localGroup == 2) {
    const statCode =
      globalStore.setting.statisticsVOMap[identityCode]?.statisticsLanding;
    setStatictis(statCode);
  }

  // 属于留存组，部署默认的统计代码
  else if (localGroup == 2) {
    setStatictis(globalStore.setting.statistics);
  }

  // 其它情况随机跳转到留存组（若有识别码携带识别码）
  else if (localGroup != 3 && localGroup != 2) {
    const toast = showLoadingToast({
      message: "正在寻找新线路..",
      forbidClick: true,
    });
    const landings = [...globalStore.setting.landingdomains];
    let isJumped = false;

    // 随机获取留存组域名，并进行拨测是否能连通
    while (landings.length) {
      // 获取随机留存组域名
      const randIdx = Math.floor(Math.random() * landings.length);
      let langdingDomain = landings.splice(randIdx, 1)[0];
      // 拨测
      try {
        await dial(langdingDomain).then(() => {
          // 携带识别码进行跳转
          if (identityCode) {
            langdingDomain = setSubDoamin(
              getRootDomain(langdingDomain),
              identityCode
            );
          }
          toast.message = "切换到新线路中..";

          if (langdingDomain.endsWith("/")) {
            location.href = langdingDomain + location.pathname.substring(1);
          } else {
            location.href = langdingDomain + location.pathname;
          }
        });
        isJumped = true;
        break;
      } catch (e) {
        console.error(e);
      }
    }
    toast.close();
    if (!isJumped) showFailToast("无可用线路");
  }
})();

import { request, DATATYPE } from '@/utils/fetch'
import { query2str } from '@/utils/common'

export default {
  // 通用设置
  GET_SETTING: () => request(`/common/getSetting?platform=1`),

  // 分类
  GET_CATEGORIES: (query={}) => request(`/common/getCategoryTree?${query2str(query)}`),

  // 公告
  GET_NOTICE: () => request('/common/getNotices?platform=1'),

  // 广告
  GET_ADS_BY_POSTTION: (positionId) => request(`/common/getAdByPosId?positionId=${positionId}`),
  GET_ADS_BY_POSTTIONs: (positionIds) => request(`/common/getAdList?positionIds=${positionIds.join(',')}`),
  SEND_ADS_LOGS: (logs) => request(`/common/adLogBatch`, {
    method: 'POST',
    body: JSON.stringify({ logs }),
    needCoded: true, //加密body
    keepalive: true, //关闭页面后不关闭请求
  }),


  // 视频
  GET_VIDEOS: (query={}) => request(`/movie/getList?${query2str(query)}`),
  GET_VIDEO: (query={}) => request(`/movie/getDetail?${query2str(query)}`),
  GET_HOME_VIDEOS: () => request(`/aggregation/home`),
  // 短视频
  GET_RELATIVE_VIDEOS: (query = {}) => request(`/movie/getDetailRelationList?${query2str(query)}`),
  
  // 顶-视频
  UPDATE_DIGG: (query = {}) => request(`/movie/updateDigg?${query2str(query)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }),
  // 收藏-视频
  // COLLECT: (body) => request('/users/collect', { method: 'POST', body: JSON.stringify(body) }),
  COLLECT: (query = {}) => request(`/users/collect?${query2str(query)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }),
  // 添加播放记录
  ADD_HISTORY: (query={}) => request(`/users/addHistory?${query2str(query)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }),
  // 评论列表-视频
  GET_COMMENT: (query = {}) => request(`/movie/getComment?${query2str(query)}`),
  // 评论-视频
  // COMMENT: (query = {}) => request(`/movie/comment?${query2str(query)}`, { method: 'POST' }, DATATYPE.urlencoded),
  COMMENT: (body) => request('/movie/comment', { method: 'POST', body: JSON.stringify(body)}),
  GET_SHORT_VIDEOS: (query={}) => request(`/movie/getShortVideoList?${query2str(query)}`),

  GET_RELATIVE_VIDEOS: (query={}) => request(`/movie/getDetailRelationList?${query2str(query)}`),
  ZAN_VIDEO: (query={}) => request(`/movie/zan?${query2str(query)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }),
  // 报错提交-视频
  ERROR_VIDEO: (body) => request('/gbook/post', {
    method: 'POST',
    body: JSON.stringify(body),
    needCoded: true
  }),
  
  // 我的-收藏
  GET_COLLECT: (query = {}) => request(`/users/getCollect?${query2str(query)}`),
  // 我的-记录
  GET_HISTORY: (query = {}) => request(`/users/getHistory?${query2str(query)}`),
  // 删除收藏
  DEL_COLLECT: (body) => request('/users/delCollect', { method: 'POST', body: JSON.stringify(body) }),
  // 删除记录
  DEL_HISTORY: (body) => request('/users/delHistory', { method: 'POST', body: JSON.stringify(body)}),

  // 拨测日志上报
  SEND_DIAL_LOGS: (logs) => request(`/common/domainLogBatch`, {
    method: 'POST',
    body: JSON.stringify({ logs }),
    needCoded: true, //加密body
    keepalive: true, //关闭页面后不关闭请求
  }),
  // 视频完播上报
  PLAY_LOG: (body) => request('/movie/playLog', { method: 'POST', body: JSON.stringify(body)}),
}
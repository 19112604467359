import registerLazyimg from './lazyimg'
import registerAdlog from './adlog'
import registerDepic from './depic'
import registerStat from './stat'
import registerDepicbg from './depicbg'

export default app => {
  registerLazyimg(app)
  registerAdlog(app)
  registerDepic(app)
  registerStat(app)
  registerDepicbg(app)
}
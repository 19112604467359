export const useRoutes = () => {
  /**
   * 注意：
   * 同属一个布局组件中，keepAlive 才生效，比如带 Default 布局组件的页面进入另一个带 Blank 布局组件的页面，Default 中的 keepAlive 将失效
   * 若想通过页面组件控制布局组件，可以通过 route.meta 元信息（props）、pinia、provide 等方式进行控制
   * 本路由中，Default 布局通过路由元信息 hideTab 控制是否隐藏底部 tabbar 选项
   */
  return [
    {
      path: "/",
      name: "index",
      component: () => import("../views/home/index.vue"),
      meta: { title: "首页", keepAlive: true },
    },
    {
      path: "/home",
      name: "home",
      component: () => import("../views/home/indexOld.vue"),
      meta: { title: "首页", keepAlive: true },
      redirect: { name: "index" },
      children: [
        {
          path: "preferred",
          name: "preferred",
          component: () => import("../views/home/preferred.vue"),
          meta: { title: "精选-首页" },
        },
        {
          path: "category",
          name: "category",
          component: () => import("../views/home/category.vue"),
          meta: { title: "分类-首页" },
        },
        {
          path: "search",
          name: "search",
          component: () => import("../views/home/search.vue"),
          meta: { title: "搜索-首页" },
        },
        {
          path: "hot",
          name: "hot",
          component: () => import("../views/home/hot.vue"),
          meta: { title: "热榜-首页" },
        },
        // {
        //   path: 'test',
        //   name: 'test',
        //   component: () => import('../views/test.vue'),
        //   meta: { title: 'Test' },
        // }
      ],
    },
    // {
    //   path: '/searchlist',
    //   name: 'searchlist',
    //   component: () => import('../views/searchlist.vue'),
    //   meta: { title: '搜索结果', hideTab:true },
    // },
    {
      path: "/search_1",
      name: "search_1",
      component: () => import("../views/home/search_1.vue"),
      meta: { title: "搜索", keepAlive: true },
    },
    {
      path: "/community",
      name: "community",
      component: () => import("../views/community/list.vue"),
      meta: { title: "社区", keepAlive: true },
    },
    {
      path: "/community/:id",
      name: "communityDetail",
      component: () => import("../views/community/detail.vue"),
      meta: { title: "正文", hideTab: true },
    },
    {
      path: "/community/add",
      name: "communityadd",
      component: () => import("../views/community/add.vue"),
      meta: { title: "发动态", layout: "blank" },
    },
    {
      path: "/community/chatgroup/:id",
      name: "communitychatgroup",
      component: () => import("../views/community/chatgroup.vue"),
      meta: { title: "聊天室", layout: "blank" },
    },
    {
      path: "/video/:id",
      name: "videoDetail",
      component: () => import("../views/videoDetail.vue"),
      meta: { title: "视频详情", hideTab: true },
    },
    {
      path: "/jpgVideo/:id",
      name: "jpgVideo",
      component: () => import("../views/testVideo.vue"),
      meta: { title: "切片测试", hideTab: true, layout: "blank" },
    },
    {
      path: "/abcdnVideo/:id",
      name: "abcdnVideo",
      component: () => import("../views/testVideoDPlayer.vue"),
      meta: { title: "视频详情", hideTab: true, layout: "blank" },
    },
    {
      path: "/books",
      name: "books",
      component: () => import("../views/books/list.vue"),
      meta: { title: "风月文学", layout: "blank", keepAlive: true },
    },
    {
      path: "/book/:id",
      name: "book",
      component: () => import("../views/books/chapter.vue"),
      meta: { title: "章节", layout: "blank" },
    },
    {
      path: "/mine",
      name: "mine",
      component: () => import("../views/mine/index.vue"),
      meta: { title: "我的" },
    },
    {
      path: "/mine/setting",
      name: "mineSetting",
      component: () => import("../views/mine/setting/index.vue"),
      meta: { title: "我的设置", layout: "blank" },
    },
    {
      path: "/mine/setting/avatar",
      name: "mineSettingAvatar",
      component: () => import("../views/mine/setting/avatar.vue"),
      meta: { title: "修改头像", layout: "blank" },
    },
    {
      path: "/mine/setting/profile",
      name: "mineSettingProfile",
      component: () => import("../views/mine/setting/profile.vue"),
      meta: { title: "修改个人资料", layout: "blank" },
    },
    {
      path: "/mine/playhistory",
      name: "playhistory",
      component: () => import("../views/mine/playHistory.vue"),
      meta: { title: "播放记录", layout: "blank" },
    },
    {
      path: "/mine/collection",
      name: "collection",
      component: () => import("../views/mine/collection.vue"),
      meta: { title: "我的收藏", layout: "blank" },
    },
    {
      path: "/mine/invite",
      name: "invite",
      component: () => import("../views/mine/invite.vue"),
      meta: { title: "我的邀请记录", layout: "blank" },
    },
    {
      path: "/shortvideo",
      name: "shortvideo",
      component: () => import("../views/shortvideo/index.vue"),
      meta: { title: "抖阴", theme: "dark" },
    },
    {
      path: "/game",
      name: "game",
      component: () => import("../views/game/index.vue"),
      meta: { title: "游戏", theme: "dark" },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/login/index.vue"),
      meta: { title: "登录", layout: "blank" },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("../views/login/register.vue"),
      meta: { title: "注册", layout: "blank" },
    },
    {
      path: "/mine/cooperation",
      name: "cooperation",
      component: () => import("../views/mine/cooperation.vue"),
      meta: { title: "商务合作", layout: "goback" },
    },
    // {
    //   path: '/test',
    //   name: 'test',
    //   component: () => import('../views/test.vue'),
    //   meta: { title: '测试' },
    // },
    {
      path: "/preferred",
      name: "preferred",
      component: () => import("../views/home/preferred.vue"),
      redirect: { name: "index" },
      meta: { title: "精选-首页" },
    },
    {
      path: "/category",
      name: "category",
      component: () => import("../views/home/category.vue"),
      redirect: { name: "index" },
      meta: { title: "分类-首页" },
    },
    {
      path: "/search",
      name: "search",
      component: () => import("../views/home/search.vue"),
      redirect: { name: "index" },
      meta: { title: "搜索-首页" },
    },
    {
      path: "/hot",
      name: "hot",
      component: () => import("../views/home/hot.vue"),
      redirect: { name: "index" },
      meta: { title: "热榜-首页" },
    },
  ];
};

export default useRoutes;

import lazy from '@/utils/lazy'

export default app => {
  /**
   * 懒加载（仅限图片）
   * @param {Object} binding.value 图片地址
   * @param {Boolean} binding.modifiers 修饰符 background 做为背景图
   */
  
  app.directive('lazyimg', {
    mounted(el, binding){
      const imgurl = binding.value
      if (!imgurl) return
      const {background, center} = binding.modifiers

      lazy(el, () => {
        if (background) {
          el.classList.add('bg-cover')
          el.classList.add(`bg-${center?'center':''}`)
          el.style.backgroundImage = `url(${imgurl})`
        } else {
          el.src = imgurl
        }
      })
    }
  })
}
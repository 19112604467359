import { defineStore } from 'pinia'
import API from '@/api'
import useGlobalStore from '@/store/global'
import { decodePicture } from '@/utils/picDecode'

export default defineStore('ad', {
  state: () => {
    return {
      adHomeBnr: [],
      adHomeSixGrid: [],
      adGameBnr: [],
      adHomeFlow: [],
      adHomeTextlinks: [],
      adHomeHotFlow: [],
      adHomeSearchBnr: [],
      adHomeCategoryBnr: [],
      adHomeCategoryFlow: [],
      adVideoSwipe: [],
      adVideoBnr: [],
      adVideoSixGrid: [],
      adVideoFlow: [],
      adVideoTextlinks: [],
      adVideoPaster: null,
      adGameMini: [],
      adGameApp: [],
      adMineBnr: [],
      adMineSettingBnr: [],
      adDialog: []
    }
  },
  getters: {

  },
  actions: {
    async sendLogs(logs) {
      return API.SEND_ADS_LOGS(logs)
    },
    
    async getGameAd() {
      const globalStore = useGlobalStore()
      // game   mini导航 10,  APP下载  20,  bnr  11
      // 首页   bnr 2, sixGird 3, flow 4, textlinks 19
      // 视频   bnr 14, flow 15, textlinks 16
      // 热榜   flow 9,  搜索 bnr 7, 分类  bnr 5, flow 6
      // 个人中心  bnr 17, 编辑  bnr 18,  公共弹窗 dialog 1
      let positionIds = [10 ,20, 11]
      await API.GET_ADS_BY_POSTTIONs(positionIds).then(res => {
        if (res.errCode == 0) {
          this.adGameMini = res.data.filter(d => d.id == 10)[0].adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          this.adGameApp = res.data.filter(d => d.id == 20)[0].adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          this.adGameBnr = res.data.filter(d => d.id == 11)[0].adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          // this.adGameMini = res.data ? res.data.filter(d => d.domainGroup == globalStore.setting.domainGroup) : []
        }
      })
    },

    async getHomeAd() {
      const globalStore = useGlobalStore()
      let positionIds = [2, 3, 4, 19, 13]
      await API.GET_ADS_BY_POSTTIONs(positionIds).then(res => {
        if (res.errCode == 0) {
          this.adHomeBnr = res.data.filter(d => d.id == 2)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          this.adHomeSixGrid = res.data.filter(d => d.id == 3)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          this.adHomeFlow = res.data.filter(d => d.id == 4)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          this.adHomeTextlinks = res.data.filter(d => d.id == 19)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          // this.adGameMini = res.data ? res.data.filter(d => d.domainGroup == globalStore.setting.domainGroup) : []
          // 获取随机的一组贴片数据
          let adPaster = res.data.filter(d => d.id == 13)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          let randomIndex = Math.floor(Math.random() * adPaster.length)
          this.adVideoPaster = adPaster[randomIndex]
          if (this.adVideoPaster?.image) {
            this.adVideoPaster.deImage = `${globalStore.imgDomain}${this.adVideoPaster.image}`
          }
        }
      })
    },

    async getVideoAd() {
      const globalStore = useGlobalStore()
      let positionIds = [14, 15, 22, 21, 23, 13]
      await API.GET_ADS_BY_POSTTIONs(positionIds).then(res => {
        if (res.errCode == 0) {
          this.adVideoSwipe = res.data.filter(d => d.id == 23)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          this.adVideoSixGrid = res.data.filter(d => d.id == 21)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          this.adVideoBnr = res.data.filter(d => d.id == 14)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          this.adVideoFlow = res.data.filter(d => d.id == 15)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          this.adVideoTextlinks = res.data.filter(d => d.id == 22)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)

          // 获取随机的一组贴片数据
          let adPaster = res.data.filter(d => d.id == 13)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          if (!adPaster?.length) return
          let randomIndex = Math.floor(Math.random() * adPaster.length)
          this.adVideoPaster = (adPaster[randomIndex])
          if (this.adVideoPaster?.image) {
            this.adVideoPaster.deImage = `${globalStore.imgDomain}${this.adVideoPaster.image}`
          }
        }
      })
    },
    async getHomeHotAd() {
      const globalStore = useGlobalStore()
      // 信息流 375 * 240
      await API.GET_ADS_BY_POSTTION(9).then(res => {
        if (res.errCode == 0) {
          this.adHomeHotFlow = res.data ? res.data.filter(d => d.domainGroup == globalStore.setting.domainGroup) : []
          // this.adHomeHotFlow = res.data ? res.data.filter(d => d.domainGroup == globalStore.setting.domainGroup) : []
        }
      })
    },
    async getMineAd() {
      const globalStore = useGlobalStore()
      let positionIds = [17, 18]
      await API.GET_ADS_BY_POSTTIONs(positionIds).then(res => {
        if (res.errCode == 0) {
          this.adMineBnr = res.data.filter(d => d.id == 17)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          this.adMineSettingBnr = res.data.filter(d => d.id == 18)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          // this.adGameMini = res.data ? res.data.filter(d => d.domainGroup == globalStore.setting.domainGroup) : []
        }
      })
    },
    async getHomeSearchAd() {
      const globalStore = useGlobalStore()
      // 横幅 640 * 134
      await API.GET_ADS_BY_POSTTION(7).then(res => {
        if (res.errCode == 0) {
          this.adHomeSearchBnr = res.data ? res.data.filter(d => d.domainGroup == globalStore.setting.domainGroup) : []
          // this.adHomeSearchBnr = res.data ? res.data.filter(d => d.domainGroup == globalStore.setting.domainGroup) : []
        }
      })
    },
    async getHomeCategoryAd() {
      const globalStore = useGlobalStore()
      let positionIds = [5, 6]
      await API.GET_ADS_BY_POSTTIONs(positionIds).then(res => {
        if (res.errCode == 0) {
          this.adHomeCategoryBnr = res.data.filter(d => d.id == 5)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          this.adHomeCategoryFlow = res.data.filter(d => d.id == 6)[0]?.adCardVOList.filter(v => v.domainGroup == globalStore.setting.domainGroup)
          // this.adGameMini = res.data ? res.data.filter(d => d.domainGroup == globalStore.setting.domainGroup) : []
        }
      })
    },
    async getadDialog() {
      const globalStore = useGlobalStore()
      // 公共页，弹窗 640 * 1138
      await API.GET_ADS_BY_POSTTION(1).then(res => {
        if (res.errCode == 0) {
          // this.adDialog = res.data ? res.data.filter(d => d.domainGroup == globalStore.setting.domainGroup) : []
          this.adDialog = res.data ? res.data.map(item => {
            return { ...item, show: true}
          }).filter(d => d.domainGroup == globalStore.setting.domainGroup) : []

        }
      })
    },
    async getAdsByPositon(positionId) {
      return API.GET_ADS_BY_POSTTION(positionId)
    },
  }
})